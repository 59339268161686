function _typeof(obj) {
  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function (obj) {
      return typeof obj;
    };
  } else {
    _typeof = function (obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function CacheMissError() {
  var err = Error.apply(this, arguments);
  err.name = this.name = 'CacheMissError';
  this.message = err.message;
  this.stack = err.stack;
}
CacheMissError.prototype = Object.create(Error.prototype, {
  constructor: {
    value: CacheMissError,
    writable: true,
    configurable: true
  }
});

var keysToCopy = ['headers', 'ok', 'redirected', 'status', 'statusText', 'trailers', 'type', 'url', 'useFinalURL', 'data']; // `res` is a Response object.
// This "clones" it into the response type returned from this lib.

function generateResponse(res) {
  var response = {};
  keysToCopy.map(function (key) {
    response[key] = res[key];
  });
  return response;
}

var responseCacheStore = {};

var accessFn = function accessFn() {
  return true;
};

var responseCache = {
  get: function get(requestKey) {
    if (responseCache.has(requestKey)) {
      var cacheObject = responseCacheStore[requestKey];
      cacheObject.accessCount += 1;
      cacheObject.lastAccessedAt = Date.now();
      return generateResponse(cacheObject.res);
    } else {
      return undefined;
    }
  },
  set: function set(requestKey, res) {
    responseCacheStore[requestKey] = {
      res: res,
      createdAt: Date.now(),
      accessCount: 0,
      lastAccessedAt: null
    };
    return responseCache;
  },
  has: function has(requestKey) {
    // `undefined` is not a valid JSON key, so we can reliably use
    // it to determine if the value exists or not.dfs
    return typeof responseCacheStore[requestKey] !== 'undefined';
  },
  delete: function _delete(requestKey) {
    if (!responseCache.has(requestKey)) {
      return false;
    } else {
      delete responseCache[requestKey];
      return true;
    }
  },
  clear: function clear() {
    responseCacheStore = {};
  },
  useCachedResponse: function useCachedResponse(fn) {
    if (typeof fn === 'function') {
      accssFn = fn;
    } else {
      throw new TypeError('The first argument to `responseCache.useCachedResponse()` must be a function.');
    }
  }
};
function shouldUseCachedValue(requestKey) {
  if (responseCache.has(requestKey)) {
    var cacheObject = responseCache.get(requestKey);
    var shouldAccess = accessFn();

    if (!shouldAccess) {
      responseCache.delete(requestKey);
    }

    return shouldAccess;
  } else {
    return false;
  }
}

var activeRequestsStore = {};
function getRequestKey() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref$url = _ref.url,
      url = _ref$url === void 0 ? '' : _ref$url,
      _ref$method = _ref.method,
      method = _ref$method === void 0 ? '' : _ref$method,
      _ref$responseType = _ref.responseType,
      responseType = _ref$responseType === void 0 ? '' : _ref$responseType,
      _ref$body = _ref.body,
      body = _ref$body === void 0 ? '' : _ref$body;

  return [url, method.toUpperCase(), responseType, body].join('||');
}
var activeRequests = {
  // Returns `true` if a request with `requestKey` is in flight,
  // and `false` otherwise.
  isRequestInFlight: function isRequestInFlight(requestKey) {
    var handlers = activeRequestsStore[requestKey];

    if (handlers && handlers.length) {
      return Boolean(handlers.length);
    } else {
      return false;
    }
  },
  clear: function clear() {
    activeRequestsStore = {};
  }
};
// resolves or rejects them.

function resolveRequest(_ref2) {
  var requestKey = _ref2.requestKey,
      res = _ref2.res,
      err = _ref2.err;
  var handlers = activeRequestsStore[requestKey] || [];
  handlers.forEach(function (handler) {
    if (res) {
      handler.resolve(generateResponse(res));
    } else {
      handler.reject(err);
    }
  }); // This list of handlers has been, well, handled. So we
  // clear the handlers for the next request.

  activeRequestsStore[requestKey] = null;
}

function bestfetch(input, options) {
  var url;
  var opts;

  if (typeof input === 'string') {
    url = input;
    opts = options || {};
  } else if (_typeof(input) === 'object') {
    opts = input || {};
    url = opts.url;
  }

  var _opts = opts,
      requestKey = _opts.requestKey,
      _opts$responseType = _opts.responseType,
      responseType = _opts$responseType === void 0 ? '' : _opts$responseType,
      _opts$dedupe = _opts.dedupe,
      dedupe = _opts$dedupe === void 0 ? true : _opts$dedupe,
      cachePolicy = _opts.cachePolicy,
      init = _objectWithoutProperties(_opts, ["requestKey", "responseType", "dedupe", "cachePolicy"]);

  var method = init.method || '';
  var upperCaseMethod = method.toUpperCase();
  var appliedCachePolicy;

  if (cachePolicy) {
    appliedCachePolicy = cachePolicy;
  } else {
    var isReadRequest = upperCaseMethod === 'GET' || upperCaseMethod === 'OPTIONS' || upperCaseMethod === 'HEAD' || upperCaseMethod === '';
    appliedCachePolicy = isReadRequest ? 'cache-first' : 'network-only';
  } // Build the default request key if one is not passed


  var requestKeyToUse = requestKey || getRequestKey({
    // If `input` is a request, then we use that URL
    url: url,
    method: init.method || '',
    body: init.body || ''
  });

  if (appliedCachePolicy !== 'network-only') {
    if (shouldUseCachedValue(requestKeyToUse)) {
      return Promise.resolve(responseCache.get(requestKeyToUse));
    } else if (cachePolicy === 'cache-only') {
      var cacheError = new CacheMissError("Response for fetch request not found in cache.");
      return Promise.reject(cacheError);
    }
  }

  var proxyReq;

  if (dedupe) {
    if (!activeRequestsStore[requestKeyToUse]) {
      activeRequestsStore[requestKeyToUse] = [];
    }

    var handlers = activeRequestsStore[requestKeyToUse];
    var requestInFlight = Boolean(handlers.length);
    var requestHandler = {};
    proxyReq = new Promise(function (resolve, reject) {
      requestHandler.resolve = resolve;
      requestHandler.reject = reject;
    });
    handlers.push(requestHandler);

    if (requestInFlight) {
      return proxyReq;
    }
  }

  var request = fetch(url, init).then(function (res) {
    var responseTypeToUse;

    if (responseType instanceof Function) {
      responseTypeToUse = responseType(res);
    } else if (responseType) {
      responseTypeToUse = responseType;
    } else if (res.status === 204) {
      responseTypeToUse = 'text';
    } else {
      responseTypeToUse = 'json';
    } // The response body is a ReadableStream. ReadableStreams can only be read a single
    // time, so we must handle that in a central location, here, before resolving
    // the fetch.


    return res[responseTypeToUse]().then(function (data) {
      res.data = data;
      responseCache.set(requestKeyToUse, res);

      if (dedupe) {
        resolveRequest({
          requestKey: requestKeyToUse,
          res: res
        });
      } else {
        return generateResponse(res);
      }
    }, function () {
      res.data = null;

      if (dedupe) {
        resolveRequest({
          requestKey: requestKeyToUse,
          res: res
        });
      } else {
        return generateResponse(res);
      }
    });
  }, function (err) {
    if (dedupe) {
      resolveRequest({
        requestKey: requestKeyToUse,
        err: err
      });
    } else {
      return Promise.reject(err);
    }
  });

  if (dedupe) {
    return proxyReq;
  } else {
    return request;
  }
}

export { CacheMissError, activeRequests, bestfetch, getRequestKey, responseCache };
